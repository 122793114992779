<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import helperFile from '../../../helpers/helper';
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Principal Corporate Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader,Multiselect },
  data() {
    return {
      
      tableData: [],
      principleBranchID:'',
      title: "Principal Corporate",
      items: [
        {
          text: "View",
        },
        {
          text: "Corporates",
          active: true,
        },
      ],
      branches:[],
      princBranches:[],
      countryArr:[],
      country : "",
      branchID:"",
      cityArr:[],
      city:"",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "corporateName",
          sortable: true,
        },
        {
          key: "branchName",
          sortable: true,
        },
        {
          key: "address",
          sortable: true,
        },
        {
          key: "created",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  created(){
 
    if(this.$storageData.login_type==4){
         (this.$storageData.profile.empTypeID==8 || this.$storageData.profile.empTypeID==18) ?
            this.getPrincipleCountry():this.principleBranchLogin();
    }
    else{
      this.getPrincipleCountry();
    }
  
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.substring = helperFile.substringFunction;
    if(this.$storageData.profile.principleBranchID==0){
      this.principleBranchID = sessionStorage.getItem('data');
    }else{
      this.principleBranchID = this.$storageData.profile.principleBranchID;
    }
    // this.readCorporateBranches(this.principleBranchID);
  },
  methods: {
    /**
     * Search the table data with search input
     */
    
    principleBranchLogin(){
        this.principleBranchID = this.$storageData.profile.principleBranchID;
       this.readCorporateBranches(this.principleBranchID);
    },
    getPrincipleCountry(){
      this.countryArr = [];
      this.country = "";
        this.axios
        .post(this.$loggedRole+"/getPrincipleCountry", {principleID:this.$storageData.profile.principleID}
        )
        .then((response) => {
                this.countryArr = response.data.data;
                // this.country = (this.countryArr) ? this.countryArr[0] : "";
                this.getPrincipleCities();
                
        });
    },
    getPrincipleCities(){
         this.axios
        .post(this.$loggedRole+"/getPrincipleCities", {principleID:this.$storageData.profile.principleID,
           'empTypeID':this.$storageData.profile.empTypeID,
              'empID':this.$storageData.profile.principleAdminID,
          country: (this.country) ? this.country.country : ""}
        )
        .then((response) => {
                this.cityArr = response.data.data;
                // this.city = (this.cityArr) ? this.cityArr[0] : "";
                 this.getPrincipleBranchByPrincipleID();
                
        });
    },

    getPrincipleBranchByPrincipleID(){
         this.axios
        .post(this.$loggedRole+"/getPrincipleBranchByPrincipleID", {
            principleID:this.$storageData.profile.principleID,
            'empTypeID':this.$storageData.profile.empTypeID,
            'empID':this.$storageData.profile.principleAdminID}
        )
        .then((response) => {
        this.princBranches = response.data.data;
        // this.branchID = (this.princBranches) ? this.princBranches[0] : "";
        this.principleBranchID = (this.branchID) ? this.branchID.principleBranchID: "";
        // this.readCorporateBranches(this.principleBranchID);
 

        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    readCorporateBranches(principleBranchID){
         this.axios
        .post(this.$loggedRole+"/viewPrincipleCorporate",{
            'principleBranchID':principleBranchID,
            'empTypeID':this.$storageData.profile.empTypeID,
            'empID':this.$storageData.profile.principleAdminID,
            'principleID':this.$storageData.profile.principleID,
            }
        )
        .then((response) => {
           this.tableData = response.data.data;
          
        });
    },
     onchangeBranch(){
        //this.principleBranchID =  this.branchID.principleBranchID;
         //this.readCorporateBranches(this.principleBranchID);
    },

    clearFilter(){
      this.country ="";
      this.city = "";
      this.branchID = "";
      this.getPrincipleCountry();
    },

    applyFilter(){
       this.principleBranchID =  this.branchID.principleBranchID;
      if(this.$storageData.profile.empTypeID==7 || this.$storageData.profile.empTypeID==18 || this.$storageData.profile.empTypeID==8 || this.$storageData.profile.empTypeID==21)
      {
        if(this.principleBranchID===undefined || this.principleBranchID=='' || this.principleBranchID==0  ||
            this.principleBranchID.length==0)
        {
          alert("Please select principle branch");
          return;
        }
      }
        this.readCorporateBranches(this.principleBranchID);
    }

  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />
     <div class="card" style="margin-top:-30px">
      <div class="card-body">
          <div class="inner mb-2 row" >
                <!-- Brand List-->
            <div class="col-md-2" style="width:16%"
              > 
                          <label>Select Country </label>
                              <multiselect v-model="country" :options="countryArr" :show-labels="false" label="country"
                                track-by="country" @input="getPrincipleCities();"></multiselect>
          </div>
        <!-- Brand List END-->
        <!-- Branch List-->
               <div class="col-md-2" style="width:16%"
         >   
                     <label>Select City </label>
                        <multiselect v-model="city" :options="cityArr" :show-labels="false" label="city"
                          track-by="city" @input="getPrincipleBranchByPrincipleID();"></multiselect>
         </div>
<!-- Branch List END-->
<!-- Branch List-->
        <div class="col-md-2" style="width:20%" >   
                <label>Select Principal Branch </label>
                <multiselect v-model="branchID" :options="princBranches" :show-labels="false" label="principleBranchName"
                            track-by="principleBranchID" @input="onchangeBranch();"></multiselect>
      </div>
<!-- Branch List END-->
 
<!-- Apply filter -->
                <div class="col-md-2"  style="width:auto; margin-top:30px;">   
                 <button class="btn btn-themeOrange"  v-on:click="applyFilter()"> Apply Filter </button>
                </div>
<!-- Apply filter END-->

<!-- Clear filter -->
                <div class="col-md-2"  style="width:16%;margin-top:30px;">   
                 <button class="btn btn-themeBrown"  v-on:click="clearFilter()"> Clear Filter </button>
                </div>
<!-- Clear filter END-->
        </div>
   </div>
 </div>
<!--FILTER END------>
     
    <!-- Cities List-->
        
        <!--END Cities List-->
     

    <div class="row" style="margin-top:30px;">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
                 <div class="row mt-4">
               <div v-if="this.$storageData.login_type == 1" class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                    <div class="btn-group" role="group">
                      <button class="btn btn-themeBrown" type="button">Excel</button>
                    </div>
               </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                      style="margin-left:5px; margin-right:5px"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      class="form-control form-control-sm ms-2"
                      placeholder="Search..."
                      type="search"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 table-striped">
              <b-table
                :current-page="currentPage" :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="tableData"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
                hover
                outlined
                responsive
                striped
                @filtered="onFiltered"

              >
              <!-- <template  v-slot:cell(corporateName)="data">
                  
                  <router-link :to="{ name: 'corporateProfile', params: { id: data.item.corpBranchID} }">
                     {{data.item.corporateName}}</router-link>
                </template>
                
               <template  v-slot:cell(Action)="data">
                  <router-link :to="{ name: 'usersTable', params: { id: data.item.corpBranchID} }">
                      <i class="fas fa-users" title="View Users"></i></router-link>
                  
                </template> -->

                <template  v-slot:cell(address)="data">
                  <span :title="data.item.address">{{substring(data.item.address)}}</span>

                </template>

              </b-table>
                
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="rows"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
